@import '../variables';

.rw-main {
  color: $rw-text-color;
  width: $rw-box-width;
  height: $rw-box-height;
  background: $rw-box-bg;
  display: flex;
  h2 {
    margin: 0 0 5px 0;
    font-weight: 300;
    font-size: 1.3rem;
    letter-spacing: 2px;
  }
  .hr {
    width: 100%;
    height: 0;
    border-bottom: solid 1px #fff;
    opacity: 0.4;
    margin: 5px 0;
  }
  p {
    margin: 0;
  }
  &.type-today {
    border-radius: $rw-box-radius;
  }
  &.type-5days {
    border-radius: $rw-box-radius $rw-box-radius 0 0;
  }
  .rw-box-left {
    padding: 10px;
    width: 60%;
  }
  .rw-box-right {
    background-color: $rw-box-right-bg;
    border-radius: 0 $rw-box-radius $rw-box-radius 0;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 60px;
    }
  }
  .rw-today {
    .date {
      color: $rw-text-secondary-color;
    }
    .current {
      font-size: 35px;
    }
    .range {
      color: $rw-text-secondary-color;
      font-size: 12px;
      margin: 0 0 5px 2px;
    }
    .desc {
      color: $rw-text-secondary-color;
      font-size: 16px;
      i {
        font-size: 20px;
        color: $rw-text-secondary-color;
      }
    }
    .info {
      color: $rw-text-secondary-color;
      div {
        margin-bottom: 5px;
      }
    }
  }
}
